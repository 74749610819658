import React, { useEffect } from "react";
import { graphql, Link, navigate } from "gatsby";
import Layout from "../components/Layout/Layout";
import { isMobile } from "../utils";

const Index = ({ data, location, pageContext }) => {
  const {
    logoSize,
    logoSizeMobile,
    logoPosition,
    entryPhoto,
    theme,
    redirectUrl,
  } = data.contentfulMetaInformation;

  const logo = `/img/${theme === "dark" ? "logo_white" : "logo@2x"}.png`;
  // positioning for image
  const positionParts = logoPosition.split("-");
  const position =
    logoPosition === "center"
      ? {
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }
      : {
          [positionParts[0]]: "20px",
          [positionParts[1]]: "20px",
        };
  const imgStyle = {
    width: isMobile() ? logoSizeMobile : logoSize,
    ...position,
  };

  // move after 5 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate(redirectUrl);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Layout hideMenu location={location}>
      <Link to={redirectUrl}>
        <img className="logo-intro" style={imgStyle} src={logo} />
        <div
          className="intro-full"
          style={{
            backgroundImage: `url(${entryPhoto.file.url})`,
            backgroundSize: "cover",
          }}
        />
      </Link>
    </Layout>
  );
};

export const query = graphql`
  query {
    contentfulMetaInformation(metaId: { eq: "Basic" }) {
      logoSize
      logoSizeMobile
      logoPosition
      theme
      redirectUrl
      entryPhoto {
        file {
          url
        }
      }
    }
  }
`;

export default Index;
